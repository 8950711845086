.fileUpload {
  background: #d1dede;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 19px;
  color: rgb(12, 8, 8);
  font-size: 0.9em;
  font-weight: bold;
  overflow: hidden;
  padding: 0.875em;
  position: relative;
  text-align: center;
  width: 120px;
}
.fileUpload:hover,
.fileUpload:active,
.fileUpload:focus {
  background: #d1dede;
}
.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  width: 120px;
  height: 35px;
}

input[type="file"] {
  position: fixed;
  right: 100%;
  bottom: 100%;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
}

.direct-upload {
  display: inline-block;
  position: relative;
  padding: 2px 4px;
  margin: 2px 3px 3px 0;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  font-size: 11px;
  line-height: 13px;
  cursor: auto;
}

.direct-upload--pending {
  opacity: 0.6;
}

.direct-upload__progress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  opacity: 0.2;
  background: #0076ff;
  transition: width 120ms ease-out, opacity 60ms 60ms ease-in;
  transform: translate3d(0, 0, 0);
  
}

.direct-upload--complete .direct-upload__progress {
  opacity: 0.4;
}

.direct-upload--error {
  border-color: red;
}

input[type="file"][data-direct-upload-url][disabled] {
  display: none;
}
