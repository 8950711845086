.prescriptionLoader {
  margin: 15px auto;
  margin-right:15%;
  width: 40px;
  height: 40px;
  position: relative;
}

.prescriptionLoader .prescriptioncircle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.prescriptionLoader .prescriptioncircle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: prescriptioncircleFadeDelay 1.2s infinite ease-in-out both;
          animation: prescriptioncircleFadeDelay 1.2s infinite ease-in-out both;
}
.prescriptionLoader .prescriptioncircle2 {
  -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
          transform: rotate(30deg);
}
.prescriptionLoader .prescriptioncircle3 {
  -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
          transform: rotate(60deg);
}
.prescriptionLoader .prescriptioncircle4 {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}
.prescriptionLoader .prescriptioncircle5 {
  -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
          transform: rotate(120deg);
}
.prescriptionLoader .prescriptioncircle6 {
  -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
          transform: rotate(150deg);
}
.prescriptionLoader .prescriptioncircle7 {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.prescriptionLoader .prescriptioncircle8 {
  -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
          transform: rotate(210deg);
}
.prescriptionLoader .prescriptioncircle9 {
  -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
          transform: rotate(240deg);
}
.prescriptionLoader .prescriptioncircle10 {
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg);
}
.prescriptionLoader .prescriptioncircle11 {
  -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
          transform: rotate(300deg); 
}
.prescriptionLoader .prescriptioncircle12 {
  -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
          transform: rotate(330deg); 
}
.prescriptionLoader .prescriptioncircle2:before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s; 
}
.prescriptionLoader .prescriptioncircle3:before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s; 
}
.prescriptionLoader .prescriptioncircle4:before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s; 
}
.prescriptionLoader .prescriptioncircle5:before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; 
}
.prescriptionLoader .prescriptioncircle6:before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s; 
}
.prescriptionLoader .prescriptioncircle7:before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s; 
}
.prescriptionLoader .prescriptioncircle8:before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s; 
}
.prescriptionLoader .prescriptioncircle9:before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.prescriptionLoader .prescriptioncircle10:before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.prescriptionLoader .prescriptioncircle11:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.prescriptionLoader .prescriptioncircle12:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}

@-webkit-keyframes prescriptioncircleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}

@keyframes prescriptioncircleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; } 
}