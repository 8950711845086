.content-center {
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pulse {
  height: 80px;
  width: 80px;
  background-color: rgb(61, 170, 153);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.pulse::before {
  content: "";
  position: absolute;
  border: 1px solid rgb(61, 170, 153);
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  border-radius: 50%;
  animation: pulse 1s linear infinite;
}

.pulse::after {
  content: "";
  position: absolute;
  border: 1px solid rgb(61, 170, 153);
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  border-radius: 50%;
  animation: pulse 1s linear infinite;
  animation-delay: 0.3s;
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.3);
    opacity: 0;
  }
}
