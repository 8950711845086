.h-divider {
    margin: auto;
    margin-top: 42px;
    width: 100%;
    position: relative;
  }
  
  .h-divider .shadow {
    overflow: hidden;
    height: 20px;
  }
  
  .h-divider .shadow:after {
    content: '';
    display: block;
    margin: -25px auto 0;
    width: 100%;
    height: 25px;
    border-radius: 125px/12px;
    box-shadow: 0 0 8px black;
  }
  
  
  .h-divider .text2 {
    width: 47px;
    height: 50px;
    position: absolute;
    bottom: 100%;
    margin-bottom: -28px;
    left: 50%;
    margin-left: -25px;
    border-radius: 100%;
    box-shadow: 0 2px 4px #999;
    background: white;
  }
  