.accordion-section {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #cdc5c5;
}

.accordion-title {
  background-color: #f7f7f7;
  color: "#3e4040";
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  /* text-decoration: underline; */
  transition: background-color 0.6s ease;
}

/* .accordion-title:hover,
.active {
  background-color: #ccc;
} */

.accordion-icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

.rotate {
  transform: rotate(90deg);
}

.accordion-content {
  background-color: white;
  overflow: auto;
  max-height: none;
  transition: max-height 0.6s ease;
}

.accordion-text {
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
}
