.video-container {
  position: relative;
  height: 85vh;
  width: 100%;
}
.video-container .main-video {
  position: relative;
  width: 100%;
  height: 100%;
}
.video-container .main-video:hover .video-toolbar {
  opacity: 1;
}
.video-container .main-video .video-toolbar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3em;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
  z-index: 2;
}
.video-container .main-video .OT_publisher {
  z-index: 1;
}
.video-container .main-video .MuiSvgIcon-root {
  font-size: 2rem;
  cursor: pointer;
  margin: 0 10px;
}
.video-container .main-video .on-icon {
  color: white;
}
.video-container .main-video .off-icon {
  color: red;
}
.video-container .additional-video {
  height: 25%;
  width: 25%;
  position: absolute;
  top: 5%;
  left: 2%;
}
