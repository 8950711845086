@import url(https://rsms.me/inter/inter.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/croppie/2.6.2/croppie.min.css);
/* @import url("https://source.zoom.us/1.7.0/css/bootstrap.css") */

.loader,
.loader:before,
.loader:after {
  background: #49beae;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #49beae;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  transform: translateZ(0);
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.content-center {
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pulse {
  height: 80px;
  width: 80px;
  background-color: rgb(61, 170, 153);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.pulse::before {
  content: "";
  position: absolute;
  border: 1px solid rgb(61, 170, 153);
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  border-radius: 50%;
  animation: pulse 1s linear infinite;
}

.pulse::after {
  content: "";
  position: absolute;
  border: 1px solid rgb(61, 170, 153);
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  border-radius: 50%;
  animation: pulse 1s linear infinite;
  animation-delay: 0.3s;
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.3);
    opacity: 0;
  }
}

.accordion-section {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #cdc5c5;
}

.accordion-title {
  background-color: #f7f7f7;
  color: "#3e4040";
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  /* text-decoration: underline; */
  transition: background-color 0.6s ease;
}

/* .accordion-title:hover,
.active {
  background-color: #ccc;
} */

.accordion-icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

.rotate {
  transform: rotate(90deg);
}

.accordion-content {
  background-color: white;
  overflow: auto;
  max-height: none;
  transition: max-height 0.6s ease;
}

.accordion-text {
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
}

.custom {
  background-color: rgb(81, 82, 115);
  color: white;
}

.scrollbar
{
	/* float: left; */
	/* height: 300px; */
	overflow-y: scroll;
}

.force-overflow
{
	/* min-height: 450px; */
}

#style-1::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #eae8cd;
}
.custom {
  background-color: rgb(81, 82, 115);
  color: white;
}

.prescriptionLoader {
  margin: 15px auto;
  margin-right:15%;
  width: 40px;
  height: 40px;
  position: relative;
}

.prescriptionLoader .prescriptioncircle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.prescriptionLoader .prescriptioncircle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #333;
  border-radius: 100%;
  animation: prescriptioncircleFadeDelay 1.2s infinite ease-in-out both;
}
.prescriptionLoader .prescriptioncircle2 {
  transform: rotate(30deg);
}
.prescriptionLoader .prescriptioncircle3 {
  transform: rotate(60deg);
}
.prescriptionLoader .prescriptioncircle4 {
  transform: rotate(90deg);
}
.prescriptionLoader .prescriptioncircle5 {
  transform: rotate(120deg);
}
.prescriptionLoader .prescriptioncircle6 {
  transform: rotate(150deg);
}
.prescriptionLoader .prescriptioncircle7 {
  transform: rotate(180deg);
}
.prescriptionLoader .prescriptioncircle8 {
  transform: rotate(210deg);
}
.prescriptionLoader .prescriptioncircle9 {
  transform: rotate(240deg);
}
.prescriptionLoader .prescriptioncircle10 {
  transform: rotate(270deg);
}
.prescriptionLoader .prescriptioncircle11 {
  transform: rotate(300deg); 
}
.prescriptionLoader .prescriptioncircle12 {
  transform: rotate(330deg); 
}
.prescriptionLoader .prescriptioncircle2:before {
  animation-delay: -1.1s; 
}
.prescriptionLoader .prescriptioncircle3:before {
  animation-delay: -1s; 
}
.prescriptionLoader .prescriptioncircle4:before {
  animation-delay: -0.9s; 
}
.prescriptionLoader .prescriptioncircle5:before {
  animation-delay: -0.8s; 
}
.prescriptionLoader .prescriptioncircle6:before {
  animation-delay: -0.7s; 
}
.prescriptionLoader .prescriptioncircle7:before {
  animation-delay: -0.6s; 
}
.prescriptionLoader .prescriptioncircle8:before {
  animation-delay: -0.5s; 
}
.prescriptionLoader .prescriptioncircle9:before {
  animation-delay: -0.4s;
}
.prescriptionLoader .prescriptioncircle10:before {
  animation-delay: -0.3s;
}
.prescriptionLoader .prescriptioncircle11:before {
  animation-delay: -0.2s;
}
.prescriptionLoader .prescriptioncircle12:before {
  animation-delay: -0.1s;
}

@keyframes prescriptioncircleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; } 
}
.box {
  /* text-align: center; */
}
.coupon {
  display: inline-block;
  border: 1px dashed #000;
  padding: 0.8em 1.2em;
  position: relative;
  font-weight: bold;
  background: #FFC107;
}

span.scissors {
  position: absolute;
  top: -12px;
  right: 0;
}
.inner {
  margin-top:'-1%';
  display: inline-block;
  border: 3px dotted #e8dddd;
  padding: 1em 2em 2em 2em;
}
.h-divider {
    margin: auto;
    margin-top: 42px;
    width: 100%;
    position: relative;
  }
  
  .h-divider .shadow {
    overflow: hidden;
    height: 20px;
  }
  
  .h-divider .shadow:after {
    content: '';
    display: block;
    margin: -25px auto 0;
    width: 100%;
    height: 25px;
    border-radius: 125px/12px;
    box-shadow: 0 0 8px black;
  }
  
  
  .h-divider .text2 {
    width: 47px;
    height: 50px;
    position: absolute;
    bottom: 100%;
    margin-bottom: -28px;
    left: 50%;
    margin-left: -25px;
    border-radius: 100%;
    box-shadow: 0 2px 4px #999;
    background: white;
  }
  
.crud {
  border-collapse: collapse;
  width: 100%;
}

.crud td,
.crud th {
  border: 1px solid #ddd;
  padding: 6px;
}

.crud tr:nth-child(even) {
  background-color: #ffffff;
}

/* .crud tr:hover {background-color: #ddd;} */

.crud th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #4f5653;
  color: white;
}

.fileUpload {
	background:#d1dede;
	border-radius: 19px;
	color: rgb(12, 8, 8);
	font-size: .9em;
	font-weight: bold;
	overflow: hidden;
	padding: 0.875em;
	position: relative;
	text-align: center;
	width: 120px;
}
.fileUpload:hover, .fileUpload:active, .fileUpload:focus {
	background: #d1dede;
}
.fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
    width: 120px;
    height: 35px;
}

input[type="file"] {
    position: fixed;
    right: 100%;
    bottom: 100%;
}
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.direct-uploadss {
  display: inline-block;
  position: relative;
  padding: 2px 4px;
  margin: 2px 3px 3px 0;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  font-size: 11px;
  line-height: 13px;
  cursor: auto;
}

.direct-uploadss--pending {
  opacity: 0.6;
}

.direct-uploadss__progress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  opacity: 0.2;
  background: #0076ff;
  transition: width 120ms ease-out, opacity 60ms 60ms ease-in;
  transform: translate3d(0, 0, 0);
}

.direct-uploadss--complete .direct-uploadss__progress {
  opacity: 0.4;
}

.direct-uploadss--error {
  border-color: red;
}

input[type="file"][data-direct-upload-url][disabled] {
  display: none;
}
.fileUpload {
	background:#d1dede;
	border-radius: 19px;
	color: rgb(12, 8, 8);
	font-size: .9em;
	font-weight: bold;
	overflow: hidden;
	padding: 0.875em;
	position: relative;
	text-align: center;
	width: 120px;
}
.fileUpload:hover, .fileUpload:active, .fileUpload:focus {
	background: #d1dede;
}
.fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
    width: 120px;
    height: 35px;
}

input[type="file"] {
    position: fixed;
    right: 100%;
    bottom: 100%;
}
.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
}

.direct-uploads {
  display: inline-block;
  position: relative;
  padding: 2px 4px;
  margin: 2px 3px 3px 0;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  font-size: 11px;
  line-height: 13px;
  cursor: auto;
}

.direct-uploads--pending {
  opacity: 0.6;
}

.direct-uploads__progress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  opacity: 0.2;
  background: #0076ff;
  transition: width 120ms ease-out, opacity 60ms 60ms ease-in;
  transform: translate3d(0, 0, 0);
}

.direct-uploads--complete .direct-uploads__progress {
  opacity: 0.4;
}

.direct-uploads--error {
  border-color: red;
}

input[type="file"][data-direct-upload-url][disabled] {
  display: none;
}
.fileUpload {
  background: #d1dede;
  border-radius: 19px;
  color: rgb(12, 8, 8);
  font-size: 0.9em;
  font-weight: bold;
  overflow: hidden;
  padding: 0.875em;
  position: relative;
  text-align: center;
  width: 120px;
}
.fileUpload:hover,
.fileUpload:active,
.fileUpload:focus {
  background: #d1dede;
}
.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  width: 120px;
  height: 35px;
}

input[type="file"] {
  position: fixed;
  right: 100%;
  bottom: 100%;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
}

.direct-upload {
  display: inline-block;
  position: relative;
  padding: 2px 4px;
  margin: 2px 3px 3px 0;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  font-size: 11px;
  line-height: 13px;
  cursor: auto;
}

.direct-upload--pending {
  opacity: 0.6;
}

.direct-upload__progress {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  opacity: 0.2;
  background: #0076ff;
  transition: width 120ms ease-out, opacity 60ms 60ms ease-in;
  transform: translate3d(0, 0, 0);
  
}

.direct-upload--complete .direct-upload__progress {
  opacity: 0.4;
}

.direct-upload--error {
  border-color: red;
}

input[type="file"][data-direct-upload-url][disabled] {
  display: none;
}

.video-container {
  position: relative;
  height: 85vh;
  width: 100%;
}
.video-container .main-video {
  position: relative;
  width: 100%;
  height: 100%;
}
.video-container .main-video:hover .video-toolbar {
  opacity: 1;
}
.video-container .main-video .video-toolbar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3em;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
  z-index: 2;
}
.video-container .main-video .OT_publisher {
  z-index: 1;
}
.video-container .main-video .MuiSvgIcon-root {
  font-size: 2rem;
  cursor: pointer;
  margin: 0 10px;
}
.video-container .main-video .on-icon {
  color: white;
}
.video-container .main-video .off-icon {
  color: red;
}
.video-container .additional-video {
  height: 25%;
  width: 25%;
  position: absolute;
  top: 5%;
  left: 2%;
}

code[class*='language-'],pre[class*='language-']{color:#c5c8c6;font-family:Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;direction:ltr;text-align:left;white-space:pre;word-spacing:normal;word-break:normal;line-height:1.5;font-size:14px;tab-size:4;-webkit-hyphens:none;hyphens:none}pre[class*='language-']{padding:1em;margin:0.5em 0;overflow:auto}:not(pre)>code[class*='language-'],pre[class*='language-']{background:#2d323e}:not(pre)>code[class*='language-']{padding:0.1em;border-radius:0.3em}.token.comment,.token.prolog,.token.doctype,.token.cdata{color:#7c7c7c}.token.punctuation{color:#c5c8c6}.namespace{opacity:0.7}.token.property,.token.keyword,.token.tag{color:#96cbfe}.token.class-name{color:#ffffb6}.token.boolean,.token.constant{color:#99cc99}.token.symbol,.token.deleted{color:#f92672}.token.number{color:#ff73fd}.token.selector,.token.attr-name,.token.string,.token.char,.token.builtin,.token.inserted{color:#a8ff60}.token.variable{color:#c6c5fe}.token.operator{color:#ededed}.token.entity{color:#ffffb6}.token.url{color:#96cbfe}.language-css .token.string,.style .token.string{color:#87c38a}.token.atrule,.token.attr-value{color:#f9ee98}.token.function{color:#dad085}.token.regex{color:#e9c062}.token.important{color:#fd971f}.token.important,.token.bold{font-weight:bold}.token.italic{font-style:italic}.token.entity{cursor:help}*{box-sizing:border-box;margin:0;padding:0}html{height:100%;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}body{background-color:#f4f6f8;height:100%}a{text-decoration:none}#root{height:100%}:not(pre)>code{font-family:Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;background-color:#eeeeee;padding:2px 4px;direction:ltr;white-space:pre;word-spacing:normal;word-break:normal;line-height:1.5;font-size:14px}

