.crud {
  border-collapse: collapse;
  width: 100%;
}

.crud td,
.crud th {
  border: 1px solid #ddd;
  padding: 6px;
}

.crud tr:nth-child(even) {
  background-color: #ffffff;
}

/* .crud tr:hover {background-color: #ddd;} */

.crud th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #4f5653;
  color: white;
}
